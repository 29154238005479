/* line 7, ../sass/reset.sass */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video,
xmp {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

/* line 102, ../sass/reset.sass */
html,
body {
  height: 100%;
}

/* line 106, ../sass/reset.sass */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* line 125, ../sass/reset.sass */
b,
strong {
  font-weight: bold;
}

/* line 132, ../sass/reset.sass */
img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

/* line 140, ../sass/reset.sass */
ul,
ol {
  list-style: none;
}

/* line 144, ../sass/reset.sass */
li {
  display: list-item;
}

/* line 151, ../sass/reset.sass */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 155, ../sass/reset.sass */
th,
td,
caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left;
}

/* line 162, ../sass/reset.sass */
q {
  quotes: none;
}

/* line 165, ../sass/reset.sass */
q:before,
q:after {
  content: "";
  content: none;
}

/* line 170, ../sass/reset.sass */
sub,
sup,
small {
  font-size: 75%;
}

/* line 175, ../sass/reset.sass */
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

/* line 181, ../sass/reset.sass */
sub {
  bottom: -0.25em;
}

/* line 184, ../sass/reset.sass */
sup {
  top: -0.5em;
}

/* line 187, ../sass/reset.sass */
svg {
  overflow: hidden;
}

/* line 5, ../sass/global.sass */
body {
  font-family: "Work Sans", sans-serif;
  background: #fff;
}

/* line 13, ../sass/global.sass */
.header {
  background: #57496e;
  padding: 20px 0;
  margin-bottom: 30px;
}
/* line 17, ../sass/global.sass */
.header--logo {
  color: #fff;
  font-size: 24px;
  padding: 0 15px;
  margin: 0;
  display: inline-block;
}
/* line 23, ../sass/global.sass */
.header--logo a {
  color: rgba(255, 255, 255, 0.5);
}
/* line 25, ../sass/global.sass */
.header--logo a:hover {
  color: #fff;
}
/* line 27, ../sass/global.sass */
.header--logo .selected {
  color: #fff;
}
/* line 29, ../sass/global.sass */
.header--logo .chevron {
  fill: rgba(255, 255, 255, 0.3);
  width: 7px;
  height: auto;
  margin: 0 10px;
}
/* line 34, ../sass/global.sass */
.header nav {
  float: right;
  margin-top: 3px;
}
/* line 37, ../sass/global.sass */
.header nav a {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 20px;
}
/* line 40, ../sass/global.sass */
.header nav a:hover {
  color: #fff;
}

/* line 44, ../sass/global.sass */
.actions--bar {
  border-bottom: 1px solid #d5f9fe;
  background: #f8feff;
  padding: 20px 0;
  margin-top: -30px;
}
/* line 2, ../sass/mixins.sass */
.actions--bar:before, .actions--bar:after {
  content: " ";
  display: table;
}
/* line 5, ../sass/mixins.sass */
.actions--bar:after {
  clear: both;
}
/* line 50, ../sass/global.sass */
.actions--bar .button {
  margin-right: 15px;
}

/* line 57, ../sass/global.sass */
h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

/* line 61, ../sass/global.sass */
a {
  color: #7c689b;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

/* line 66, ../sass/global.sass */
p {
  font-family: "Cousine";
  margin-bottom: 30px;
}

/* line 70, ../sass/global.sass */
.bounds {
  max-width: 1250px;
  margin: 0 auto;
}

/* line 74, ../sass/global.sass */
.grid, .grid-25, .grid-33, .grid-66, .grid-100 {
  float: left;
  padding: 0 15px;
  box-sizing: border-box;
}
/* line 78, ../sass/global.sass */
.grid-25 {
  width: 25%;
}
/* line 81, ../sass/global.sass */
.grid-33 {
  width: 33.3%;
}
/* line 84, ../sass/global.sass */
.grid-66 {
  width: 66.6%;
}
/* line 87, ../sass/global.sass */
.grid-100 {
  width: 100%;
}
/* line 90, ../sass/global.sass */
.grid-right {
  float: right;
}

/* line 93, ../sass/global.sass */
.centered {
  float: none;
  margin: 0 auto;
}

/* line 102, ../sass/global.sass */
.course--module {
  background: #7c689b;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
  min-height: 107px;
  display: block;
  position: relative;
  box-sizing: border-box;
}
/* line 2, ../sass/mixins.sass */
.course--module:before, .course--module:after {
  content: " ";
  display: table;
}
/* line 5, ../sass/mixins.sass */
.course--module:after {
  clear: both;
}
/* line 112, ../sass/global.sass */
.course--module--title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* line 116, ../sass/global.sass */
.course--label {
  font-family: "Cousine";
  font-size: 14px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  font-weight: normal;
}
/* line 123, ../sass/global.sass */
.course--title {
  font-size: 18px;
  color: #fff;
}
/* line 126, ../sass/global.sass */
.course--link {
  display: block;
  cursor: pointer;
  text-decoration: none;
  border: 0;
}
/* line 133, ../sass/global.sass */
.course--add--module {
  background: #eee;
  text-align: center;
}
/* line 137, ../sass/global.sass */
.course--add--module:hover .course--add--title {
  color: #444;
}
/* line 139, ../sass/global.sass */
.course--add--module:hover .add {
  fill: #444;
}
/* line 141, ../sass/global.sass */
.course--add--title {
  color: #999;
  display: inline-block;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%);
  -moz-transform: translate(-50%, -55%);
  -ms-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
}
/* line 149, ../sass/global.sass */
.course--add--title .add {
  width: 13px;
  height: auto;
  margin: 0 5px 0 0;
  fill: #999;
}
/* line 159, ../sass/global.sass */
.course--detail {
  margin-top: 60px;
}
/* line 162, ../sass/global.sass */
.course--header {
  margin-bottom: 30px;
}
/* line 164, ../sass/global.sass */
.course--header .course--label {
  font-size: 18px;
  color: #999;
}
/* line 167, ../sass/global.sass */
.course--header .course--title {
  font-size: 48px;
  margin-bottom: 15px;
  color: #332a40;
}
/* line 171, ../sass/global.sass */
.course--header p {
  font-size: 18px;
  color: #b1a5c4;
  font-weight: 100;
}
/* line 176, ../sass/global.sass */
.course--title--input {
  font-size: 48px;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
}
/* line 186, ../sass/global.sass */
.course--description {
  margin-bottom: 30px;
}
/* line 188, ../sass/global.sass */
.course--description textarea {
  margin-bottom: 60px;
}
/* line 190, ../sass/global.sass */
.course--description p {
  font-size: 16px;
  line-height: 1.8;
  color: #444;
}
/* line 195, ../sass/global.sass */
.course--description ol,
.course--description ul {
  list-style-type: none;
}
/* line 198, ../sass/global.sass */
.course--description li {
  position: relative;
  padding-left: 30px;
  padding: 30px 0 30px 5%;
  border-top: 1px solid #ccc;
  font-size: 16px;
  line-height: 1.8;
  font-family: "Cousine";
}
/* line 2, ../sass/mixins.sass */
.course--description li:before, .course--description li:after {
  content: " ";
  display: table;
}
/* line 5, ../sass/mixins.sass */
.course--description li:after {
  clear: both;
}
/* line 208, ../sass/global.sass */
.course--description ol li {
  counter-increment: step-counter;
}
/* line 210, ../sass/global.sass */
.course--description ol li:before {
  content: counter(step-counter);
  color: #999;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 34px;
}
/* line 219, ../sass/global.sass */
.course--description ul li:before {
  content: "";
  float: left;
  margin: 9px 0 0 -30px;
  width: 5px;
  height: 5px;
  border: 1px solid #999;
  border-radius: 10px;
  display: block;
}
/* line 229, ../sass/global.sass */
.course--description h1,
.course--description h2,
.course--description h3 {
  font-family: "Work Sans", sans-serif;
  color: #222;
  margin-bottom: 30px;
}
/* line 235, ../sass/global.sass */
.course--description h1 {
  font-size: 36px;
}
/* line 237, ../sass/global.sass */
.course--description h2 {
  font-size: 30px;
}
/* line 239, ../sass/global.sass */
.course--description h3 {
  font-size: 24px;
}
/* line 241, ../sass/global.sass */
.course--description h4 {
  font-size: 18px;
  font-weight: 100;
  margin: 0 0 10px;
  color: #999;
  display: block;
}
/* line 287, ../sass/global.sass */
.course--stats--list h4 {
  font-family: "Cousine";
  font-weight: 100;
  color: #999;
  font-size: 14px;
  padding: 0 0 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* line 300, ../sass/global.sass */
.course--stats--list h3 {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
}
/* line 303, ../sass/global.sass */
.course--stats--list > li {
  margin-bottom: 50px;
}
/* line 305, ../sass/global.sass */
.course--stats--list > li ul {
  padding-left: 20px;
}
/* line 307, ../sass/global.sass */
.course--stats--list > li li {
  margin-bottom: 15px;
  list-style-type: circle;
}
/* line 311, ../sass/global.sass */
.course--time--input {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

/* line 460, ../sass/global.sass */
.signin,
.signup {
  margin-top: 60px;
}

/* line 468, ../sass/global.sass */
textarea {
  width: 100%;
  min-height: 300px;
  border-radius: 8px;
  background: #fff;
  padding: 15px;
  font-size: 16px;
  margin: 0 0 20px;
  border: 1px solid #ccc4d8;
  color: #666;
  line-height: 1.8;
  outline: none;
  font-family: "Cousine";
  box-sizing: border-box;
}
/* line 29, ../sass/mixins.sass */
textarea::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 16px;
}
/* line 31, ../sass/mixins.sass */
textarea:-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
}
/* line 33, ../sass/mixins.sass */
textarea::-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
}
/* line 35, ../sass/mixins.sass */
textarea:-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
}
/* line 485, ../sass/global.sass */
textarea:focus {
  border-color: #7c689b;
}

/* line 488, ../sass/global.sass */
input {
  width: 100%;
  color: #332a40;
  border-radius: 8px;
  border: 1px solid #ccc4d8;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  box-sizing: border-box;
  font-size: 16px;
}
/* line 29, ../sass/mixins.sass */
input::-webkit-input-placeholder {
  font-weight: normal;
}
/* line 31, ../sass/mixins.sass */
input:-moz-placeholder {
  font-weight: normal;
}
/* line 33, ../sass/mixins.sass */
input::-moz-placeholder {
  font-weight: normal;
}
/* line 35, ../sass/mixins.sass */
input:-ms-input-placeholder {
  font-weight: normal;
}
/* line 500, ../sass/global.sass */
input:focus {
  border-color: #7c689b;
}

/* line 29, ../sass/mixins.sass */
.input-title::-webkit-input-placeholder {
  font-weight: bold;
}
/* line 31, ../sass/mixins.sass */
.input-title:-moz-placeholder {
  font-weight: bold;
}
/* line 33, ../sass/mixins.sass */
.input-title::-moz-placeholder {
  font-weight: bold;
}
/* line 35, ../sass/mixins.sass */
.input-title:-ms-input-placeholder {
  font-weight: bold;
}

/* line 507, ../sass/global.sass */
.button {
  background: #7c689b;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  padding: 15px 30px;
  border: 0;
  margin-right: 10px;
  border-radius: 8px;
  font-weight: bold;
  display: inline-block;
  box-sizing: border-box;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -ms-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
}
/* line 520, ../sass/global.sass */
.button:focus {
  outline: none;
}
/* line 522, ../sass/global.sass */
.button:not(.button-secondary):hover {
  background: #63537d;
}

/* line 525, ../sass/global.sass */
.button-secondary {
  color: #7c689b;
  background: none;
  box-shadow: 0 0 0 2px #7c689b inset;
  -webkit-transition: box-shadow 0.2s, color 0.2s;
  -moz-transition: box-shadow 0.2s, color 0.2s;
  -ms-transition: box-shadow 0.2s, color 0.2s;
  -o-transition: box-shadow 0.2s, color 0.2s;
  transition: box-shadow 0.2s, color 0.2s;
}
/* line 530, ../sass/global.sass */
.button-secondary:hover {
  color: #63537d;
  box-shadow: 0 0 0 2px #63537d inset;
}

/* adds additional padding to the bottom of an element */
.pad-bottom {
  padding-bottom: 40px;
}

/* added additional class name for header elements */
.signout {
  margin-top: 60px;
}

/* styles the signed in user's name in the header */
.header nav span {
  color: #fff;
  margin-left: 20px;
}

/* styles the validation errors */
.validation--errors--label {
  color: #7c689b;
  font-size: 28px;
}
.validation-errors ul {
  color: #7c689b;
  padding: 15px 0 40px 10px;
}

/* reduce bottom margin on the course description textarea element */
.course--description textarea {
  margin-bottom: 20px;
}
